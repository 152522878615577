::-webkit-scrollbar {
  display: none;
}

@font-face {
  font-family: quicksand;
  src: url("../assets/fonts/Quicksand-Light.ttf");
  font-weight: 100;
}

@font-face {
  font-family: quicksand;
  src: url("../assets/fonts/Quicksand-Medium.ttf");
  font-weight: 300;
}

@font-face {
  font-family: quicksand;
  src: url("../assets/fonts/Quicksand-Regular.ttf");
  font-weight: 500;
}

@font-face {
  font-family: quicksand;
  src: url("../assets/fonts/Quicksand-SemiBold.ttf");
  font-weight: 700;
}

@font-face {
  font-family: quicksand;
  src: url("../assets/fonts/Quicksand-Bold.ttf");
  font-weight: 900;
}

body {
  font-family: quicksand;
}

.react-datetime-picker__wrapper {
  border: none !important;
}

.react-calendar__tile--active {
  color: #ffffff !important;
  background-color: #3466aa !important;
}
